<template>
  <div>
    <card
      :key="keyID"
      :content="content"
      :schema="get_companySchema"
      :form-data="get_companyData"
      :editable="get_companyEditable"
      @update-form="updateCompany"
      @add-element="addFacility"
      @submitAction="addCompany"
    />
    <modal v-if="showModal" :modal-dialog="showModal">
      <router-view name="modalDialog" :key="$route.fullPath" />
    </modal>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Card from "../components/Card";
import Modal from "../components/Modal";
import confCompany from "../conf/confCompany.json";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Company",
  components: { Card, Modal },
  title() {
    return (
      this.$t(this.$route.meta.title) +
      " – " +
      this.$store.getters["auth/getUserName"]
    );
  },

  data() {
    return {
      content: confCompany.content,
      showModal: this.$route.meta.showModal,
      keyID: "company"
    };
  },
  created() {
    this.$store.dispatch("company/loadCompany");
  },
  watch: {
    "$route.meta"({ showModal }) {
      this.showModal = showModal;
    }
  },
  computed: {
    ...mapGetters("company", [
      "get_companyData",
      "get_companySchema",
      "get_companyEditable"
    ])
  },
  methods: {
    ...mapActions("company", [
      "updateCompany",
      "addFacility",
      "addCompany",
      "loadCompany"
    ])
  }
};
</script>

<style scoped></style>
