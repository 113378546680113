<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    overlay-opacity="0.5"
    persistent
    overflow
    v-model="modalDialog"
  >
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  name: "Modal",
  props: ["modalDialog"]
};
</script>

<style scoped></style>
